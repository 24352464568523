import React from 'react';

const CheckoutNotes = function({curCart, content}) {
    
    return (
        <div>
            <div className={(curCart.length > 0 ? "hide" : "show")}>
                <p>Your cart is empty. You can still submit the form on this page. Please specify in the Shipping and Billing input field what products your are interested in.</p>
            </div>
        </div>
    )
}

export default CheckoutNotes;