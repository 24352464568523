import React, {useState} from "react"
import axios from "axios"
import Header from "../components/header"
import Container from "../components/container"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"
import { connect } from 'react-redux';
import {updateCart} from "../state/app"
import CheckoutNotes from "../components/checkout-notes"
import GenBtn from "../components/gen-button"

const formSchema = Yup.object().shape({
    _replyto: Yup.string()
      .email("Invalid email")
      .required("Required"),
    // fullname: Yup.string().required("Required"),
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    company: Yup.string(),
    description: Yup.array(),
    // payment: Yup.string().required("Required"),
    shipping: Yup.string().required("Required"),
    billing_same_shipping: Yup.string().required("Required"),
    billing: Yup.string().required("Required"),
    card: Yup.string(),
    speed: Yup.string().required("Required"),
    po: Yup.string(),
    // upload: Yup.mixed().test(
    //   "fileSize",
    //   "File too large",
    //   (value, context) => value && value.size <= 6094193
    // ).test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value, context) => value && ["application/pdf"].includes(value.type)
    // ),
    items: Yup.string(),
    subtotal: Yup.string(),
    _cc: Yup.string()
  });

// const Upload = (props) => {

//   const handleChange = e => {
//     e.preventDefault();
//     let reader = new FileReader();
//     let file = e.target.files[0];
//     console.log("Selected file: ", file);
//     if(file) {
//       reader.readAsDataURL(file);
//       props.setFieldValue(props.field.name, file)
//     }
//   }
//   return (
//     <input id="upload" type="file" onChange={handleChange} name={props.field.name} accept="application/pdf" />
//   )
// }

const CheckoutForm = ({cart, dispatch}) => {

    
    let newCart, curCart = [];
    let curTotal = 0;
    curCart = [...cart];

    let itemReducer = function(el) {
       
      return (
          el.reduce((acc, cur) => {
              return acc + cur.qty;
          }, 0)
      )
  }

  let curNumberItems = cart.length <= 1 ? cart.length < 1 ? 0 : cart[0].qty : itemReducer(cart) ;

    function sub(sku) {
        let subCounter = 1;
        let curItem;
        curItem = curCart.find(el => el.sku === sku)

        if(curItem.qty > 1) {

            newCart = curCart.map(el => {
                if(el.sku !== sku) {
                    return el;
                } else {
                    return {sku: el.sku, price: el.price, qty: el.qty - subCounter, name: el.name}
                }
            })

        } else {
            newCart = curCart.filter(el => el.sku !== sku)
        }

        dispatch(updateCart(newCart))
        newCart = [];
    }

    function add(sku) {
        let addCounter = 1;
        // console.log('add: ', sku)
        //map and find element then add 1
        newCart = curCart.map(el => {
            if(el.sku !== sku) {
                return el
            } else {
                return {sku: el.sku, price: el.price, qty: el.qty + addCounter, name: el.name}
            }
        })
        dispatch(updateCart(newCart))
        newCart = [];
    }

    const cc = "info@ifi-test.com,dina@ifi-test.com,sales@ifi-test.com,karl@ifi-test.com"

    const curItems = curCart.map(el => {
      return `- qty: ${el.qty}, sku: ${el.sku}, price: $${el.price} USD, name: ${el.name}`
    }).join("\n")

    if(curCart.length > 0){
        
      curCart.forEach(el => {
          let sum = el.qty * el.price;
          curTotal += sum;
      })
  }

    const curTotalCalc = "$" + curTotal.toString() + " USD. Shipping and taxes calculated after submission. $35 min shipping."

    const placeholderText = "Enter Full Shipping Address"

    const placeholderTextBilling = "Enter Full Billing Address"

     /* Server State Handling */
     const [serverState, setServerState] = useState();
     const [showForm, setShowForm] = useState(true);
     const handleServerResponse = (ok, msg) => {
       setServerState({ok, msg});
       setShowForm(!showForm)
     };

     const handleOnSubmit = (values, actions) => {
      //  let newValues = {...values, upload: values.upload.name}
      //  console.log("Submitted values", newValues)
       axios({
         method: "POST",
         url: "https://formspree.io/f/xyyjnanl",
         data: values
       })
         .then(response => {
           actions.setSubmitting(false);
           actions.resetForm();
           handleServerResponse(true, "Thanks! Your order form has been submitted. Please check your email.");
           newCart = []
           dispatch(updateCart(newCart))
         })
         .catch(error => {
           actions.setSubmitting(false);
           handleServerResponse(false, error.response.data.error);
         });
     };

     return (
       <section className="small" style={{marginBottom: "10rem"}}>
           <Container>
                <div className="center-text" style={{paddingBottom: "3rem"}}>
                <Header headerText="Online Order Form" />
                <GenBtn color="blue" button="Product List" link="shop"/>
                </div>
                <div className="flex">
                <div className={["col", "info"].join(' ')}>
                        <Header headerText={"In your cart: " + curNumberItems.toString()} headingTag="h3" classTag="h4" />
                    {curCart.map((el, index) => (
                        <div key={index} style={{borderBottom: ".1rem solid #a5a5a5", margin: "1.5rem 0 1rem 0"}}>
                                <div>
                                    <button className={["formcartbutton", "blue"].join(" ")} onClick={(e)=>sub(el.sku)} >-</button>
                                    <span className={"formcartbutton"}>{el.qty}</span> 
                                    <button className={["formcartbutton", "blue"].join(" ")} onClick={(e)=>add(el.sku)} >+</button>
                                </div>
                                <div>
                                    <p>{el.sku} - {el.price > 0 ? "$" + el.price + " USD" : "Inquire Only"} - {el.name}</p>    
                                </div>
                        </div>
                    ))}
                    <CheckoutNotes curCart={cart}/>
                </div>
                <div className={["col", "colbgblue", "colflex"].join(' ')}>
                <Formik
                initialValues={{ _replyto: "", firstname: "", lastname: "", shipping: "", billing_same_shipping: "", billing: "Same as shipping", phone: "", company: "", description: [], payment: "phone", upload: "", items: curItems, card: "", speed: "", po: "", subtotal: curTotalCalc, _cc: cc }}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                      <Form id="fs-frm" noValidate>
                      <div className={(showForm ? "show" : "hide" )}>
                        <div className="field"></div>
                        <div className="field"></div>
                        <div style={{margin: "1rem 0"}}></div>
                        <div className="messagecustomer">
                            <p>We currently do not accept payments online, but it is something we are considering in the future. In the meantime, submit this form, relax and we will contact you to collect payment information.</p>
                        </div>    
                        <div className="field">
                            <div className="label">
                              <label htmlFor="email">Email:</label>
                            </div>
                            <div className="control">
                                <Field className="input" id="email" type="email" name="_replyto" />
                            </div>
                            <ErrorMessage name="_replyto" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                            <div className="label">
                              <label htmlFor="firstname">Firstname:</label>
                            </div>
                            <div className="control">
                                <Field className="input" id="firstname" type="text" name="firstname" />
                            </div>
                            <ErrorMessage name="firstname" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                            <div className="label">
                              <label htmlFor="lastname">Lastname:</label>
                            </div>
                            <div className="control">
                                <Field className="input" id="lastname" type="text" name="lastname" />
                            </div>
                            <ErrorMessage name="lastname" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                            <div className="label">
                              <label htmlFor="phone">Phone:</label>
                            </div>
                            <div className="control">
                                <Field className="input" id="phone" type="tel" name="phone" />
                            </div>
                            <ErrorMessage name="phone" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                            <div className="label">
                              <label htmlFor="company">Company name:</label>
                            </div>
                            <div className="control">
                                <Field className="input" id="company" type="text" name="company" />
                            </div>
                            <ErrorMessage name="company" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                          <div className="label">Best describes you:</div>
                          <div className="control">
                            <div className={["select", "ismultiple", "is-primary"].join(" ")}>
                              <Field
                              component="select"
                              id="description"
                              name="description"
                              multiple={true}
                            >
                                <option value="Current Customer">Current Customer</option>
                                <option value="Work for a Lab">Work for a Lab</option>
                                <option value="Reseller">Reseller</option>
                                <option value="Personal Use">Personal Use</option>
                              </Field>
                            </div>
                          </div>
                        </div>
                        <div style={{margin: "1rem 0"}}></div>
                        <div className="messagecustomer">
                            <p>
                            For current customers, you can add your PO number on the form and indicate if you want us to use your card on file.
                            </p>
                        </div>
                        <div className="field">
                          <div className="label">
                              <label htmlFor="po">PO Number</label>
                          </div>
                          <div className="control">
                          <Field className="input" id="po" name="po" placeholder="PO Number" />
                          </div>
                          <ErrorMessage name="po" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                          <div className="label">
                              <label htmlFor="card">Use Card On File</label>
                          </div>
                          <div className="control">
                          <div role="group" aria-labelledby="my-radio-group">
                              <label style={{margin: '.8rem'}} htmlFor="card">
                                <Field type="radio" name="card" value="Yes" />
                                <span style={{paddingLeft: ".5rem"}}>Yes</span>
                              </label>
                              <label style={{margin: '.8rem'}} htmlFor="card">
                                <Field type="radio" name="card" value="No" />
                                <span style={{paddingLeft: ".5rem"}}>No</span>
                              </label>
                              <label style={{margin: '.8rem'}} htmlFor="card">
                                <Field type="radio" name="card" value="n/a" />
                                <span style={{paddingLeft: ".5rem"}}>N/A</span>
                              </label>
                            </div>
                            <ErrorMessage name="card" className="errormsg" component="p" />
                          </div>
                        </div>
                        {/* <div className="field">
                            <div className="field">
                              <label htmlFor="upload">Upload Editable Form If Applicable:</label>
                            </div>
                            <div className="control">
                                <Field name="upload" component={Upload} setFieldValue={setFieldValue} />
                            </div>
                            <ErrorMessage name="upload" className="errormsg" component="p" />
                        </div> */}
                        <div style={{margin: "1rem 0"}}></div>
                        <div className="messagecustomer">
                            <p>Shipping and Taxes are calculated after submission.</p>
                        </div>
                        <div style={{margin: "1rem 0"}}></div>
                        <div className="messagecustomer">
                            <p>$35 shipping minimun. We ship FedEx Ground. For expedited shipping, please select expedited shipping on the form. We will contact you with expedited shipping options.</p>
                        </div>
                        <div className="field">
                          <div className="label">
                              <label htmlFor="speed">Shipping Method</label>
                          </div>
                          <div className="control">
                            <div role="group" aria-labelledby="my-radio-group">
                              <label style={{margin: '.8rem'}} htmlFor="speed">
                                <Field type="radio" name="speed" value="Fedex Ground" />
                                <span style={{paddingLeft: ".5rem"}}>Fedex Ground</span>
                              </label>
                              <label style={{margin: '.8rem'}} htmlFor="speed">
                                <Field type="radio" name="speed" value="Fedex- Expedited Shipping" />
                                <span style={{paddingLeft: ".5rem"}}>Fedex Expedited</span>
                              </label>
                            </div>
                          </div>
                          <ErrorMessage name="speed" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                          <div className="label">
                            <label htmlFor="shipping">Shipping:</label>
                          </div>
                          <div className="control">
                            <Field id="shipping" name="shipping" component="textarea" className="textarea" placeholder={placeholderText} rows="7" />
                          </div>
                          <ErrorMessage name="shipping" className="errormsg" component="p" />
                        </div>
                        <div className="field">
                          <div className="label">
                              <label htmlFor="billing_same_shipping">Billing same as Shipping</label>
                          </div>
                          <div className="control">
                            <div role="group" aria-labelledby="my-radio-group">
                              <label style={{margin: '.8rem'}} htmlFor="billing_same_shipping">
                                <Field type="radio" name="billing_same_shipping" value="yes" />
                                <span style={{paddingLeft: ".5rem"}}>Yes</span>
                              </label>
                              <label style={{margin: '.8rem'}} htmlFor="billing_same_shipping">
                                <Field type="radio" name="billing_same_shipping" value="no" />
                                <span style={{paddingLeft: ".5rem"}}>No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorMessage name="billing_same_shipping" className="errormsg" component="p" />
                        </div>
                        {values.billing_same_shipping === "no" && <div className="field">
                          <div className="label">
                            <label htmlFor="billing">Billing:</label>
                          </div>
                          <div className="control">
                            <Field id="billing" name="billing" component="textarea" className="textarea" placeholder={placeholderTextBilling} rows="7" />
                          </div>
                          <ErrorMessage name="billing" className="errormsg" component="p" />
                        </div>}
                        <div className="field">
                          <div className="control">
                            <button type="submit" disabled={isSubmitting} className={["formbtn", "blue"].join(' ')} style={{marginTop: "2rem"}}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      {serverState && (
                          <p className={!serverState.ok ? "errormsg" : ""} style={{paddingRight: "2rem", paddingLeft: "2rem", textAlign: "center"}}>
                          {serverState.msg}
                          </p>
                      )}
                      </Form>
                  )}
                </Formik>
                </div>
              </div>
           </Container>
       </section>
     );
}

export default connect(state => ({
    cart: state.app.cart
  }), null)(CheckoutForm)