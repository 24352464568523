import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckoutForm from "../components/checkout-form"

export default function Checkout() {
    const pageTitle = "Online Order Form"
    const pageDescription = "Online Order Form. Online Order Page. Independent Forensics is a unique DNA research and analysis laboratory with a powerful record of breaking through barriers in commercial science. Our experienced team has over 60 combined years of experience in the DNA and related scientific fields."

    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <CheckoutForm />
        </Layout>
    )
}